<template>
    <div v-loading="is_panding" class="orders-management">
        <div class="orders-management-tools">
            <el-input style="width: 30%;" v-model="search" placeholder="חיפוש..." />
            <el-select
                style="margin: 0 5px;"
                v-model="filters.status"
                class="m-2"
                placeholder="סטטוס"
                size="large"  
            >
                <el-option
                v-for="staus in statuses_options"
                :key="staus"
                :value="staus"
                />
            </el-select>
            <el-button @click="init" type="success">סינון</el-button>
        </div>
        <div class="orders-management-content">
            <h2 v-if="!filter_by_search.length">לא קיימות רשומות!</h2>
            <table v-else>
                <tr>
                    <th>מס הזמנה</th>
                    <th>שם הסניף</th>
                    <th>שם הספק</th>
                    <th>סטטוס</th>
                    <th>תאריך</th>
                    <th>פעולות</th>
                </tr>
                <template v-for="order in slice_data" :key="order.uid">
                    <tr>
                        <td>{{order.order_num}}</td>
                        <td>{{order.branche}}</td>
                        <td>{{order.supplier_name}}</td>
                        <td>{{order.status}}</td>
                        <td>{{new Date(order.created_at.seconds * 1000).toLocaleString('he')}}</td>
                        <td>
                            <i @click="handle_show_info(order)" class="material-icons icon edit-icon">info</i>
                            <i @click="handle_delete_order(order)" class="material-icons icon delete-icon" >delete</i>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="orders-management-pagination">
            <el-pagination 
                v-model:currentPage="pagination.current_page"
                background 
                :page-size="pagination.number_per_page"
                layout="prev,pager,next"
                :total="filter_by_search.length" 
            />
        </div>

        <OrderDetailsModal 
            v-if="selected_order_for_display"
            :order="selected_order_for_display"
            @close="selected_order_for_display=null"
            @update_order_status="handle_update_order_status"
        />
    </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import {orders,get_orders_from_db} from './store'
import {slide_pop_error,alert,alert_confirm} from '../../../../Methods/Msgs'
import {update_status_order_in_db,delete_order_from_local_and_db} from './store'
export default {
    components:{
        OrderDetailsModal:defineAsyncComponent(() => import('./orders/OrderDetailsModal.vue'))
    },
    setup () {
        
        const is_panding = ref(false)
        const search = ref('')

        const selected_order_for_display = ref(null)

        const filters = ref({
            status:'חדש'
        })

        const statuses_options = ref([
            'הכל',
            'חדש',
            'סגור'
        ])
        

        const pagination = ref({
            current_page:1,
            number_per_page:10,
        })

        const slice_data = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page-1)*pagination.value.number_per_page,
            ((pagination.value.current_page-1)*pagination.value.number_per_page)+pagination.value.number_per_page)
        }))

        const filter_by_search = computed(() => {
            if(!search.value) return orders.value
            return orders.value.filter(o => {
                if(o.branche.includes(search.value)) return o
                if(o.order_num.includes(search.value)) return o
                if(o.supplier_name.includes(search.value)) return o
                if(o.supplier_num.includes(search.value)) return o 
            })
        })

        const filter_by_status = computed(() => {
            if(filters.value.status != 'הכל') return filters.value.status
            return null
        })

        const handle_show_info = (order) => {
            selected_order_for_display.value = JSON.parse(JSON.stringify(order));
        }

        const handle_update_order_status = async() => {
            try{
                await update_status_order_in_db(selected_order_for_display.value)
                alert('success','הצלחה',
                `עדכון סטטוס להזמנה ${selected_order_for_display.value.order_num} עודכן ל${selected_order_for_display.value.status}`)
                selected_order_for_display.value = null
            }catch(err){
                console.error(err.message)
                slide_pop_error(err.message)
            }
        }

        const handle_delete_order = async(order) => {
            alert_confirm(`למחוק את הזמנה מס: ${order.order_num}?`)
            .then(async res => {
                if(res.isConfirmed){
                    is_panding.value = true
                    await delete_order_from_local_and_db(order)
                    is_panding.value = false
                }
            }).catch((err) => {
                console.error(err.message)
                slide_pop_error(err.message)
            })
        }

    
        const init = async() => {
            try{
                is_panding.value = true
                await get_orders_from_db(filter_by_status.value)
                is_panding.value = false
            }catch(err){
                is_panding.value = false
                console.error(err.message)
            }
        }

        init()
        return {
            handle_show_info,
            handle_delete_order,
            handle_update_order_status,
            init,
            orders,
            is_panding,
            search,
            pagination,
            filter_by_search,
            statuses_options,
            filters,
            slice_data,
            selected_order_for_display,
        }
    }
}
</script>

<style  scoped>
    .orders-management{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .orders-management-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .orders-management-content{
        width: 100%;
        flex: 1;
        overflow-y: auto;
    }
    .orders-management-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th{
          position:unset;  
        }
    }

    .icon{
        user-select: none;
        cursor: pointer;
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }
    .delete-icon{
        color: var(--danger);
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--purple);
        color: #fff;
    }
</style>